import React, { useEffect, useContext, useState } from "react"
import { Step, StepLabel, Typography, Box, Button } from "@material-ui/core"
import FreeLicenceSelector from "../components/FreeLicenceSelector"
import Alert from "@material-ui/lab/Alert"
import { ManualActivationContext } from "../ManualActivationContext"
import LicenceServer from "../../servers/LicenceServer"
import { AppContext } from "../../context/AppContext"
import { Localization } from "../../localization/Localization"

function SelectLicenceToActivateStep(props) {
  const { manualActivationState, manualActivationDispatch } = useContext(
    ManualActivationContext
  )

  const { appState, appDispatch } = useContext(AppContext)

  var selectedLicenceSetter = id => {
    manualActivationDispatch({ type: "setSelectedLicence", data: id })
  }

  var onNextButtonClick = () => {
    if (manualActivationState.selectedLicence === null) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: (
            <Localization>
              manual_activation.please_select_licence
            </Localization>
          )
        }
      })
      return
    }

    props.onNext()
  }

  return (
    <>
      <FreeLicenceSelector
        selectedLicenceSetter={selectedLicenceSetter}
        product={manualActivationState.requestedProduct}
        requestedComputers={manualActivationState.requestFileObject.requestedComputers}
      />
      
      <Box mt={3}>
          <Button
            onClick={onNextButtonClick}
            variant="contained"
            color="primary"
          >
            <Localization>manual_activation.activate</Localization>
          </Button>
        </Box>
    </>
  )
}

export default SelectLicenceToActivateStep
